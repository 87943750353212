<template>
  <textarea
    ref="content"
    v-model="content"
    class="tf_comm"
    :placeholder="placeholder"
    @input="content = $event.target.value"
    @keyup="countChar"
  />
</template>

<script>
export default {
  name: "CommTextareaCharCount",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    maxCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    countChar() {
      let charCount = this.content.length;
      if (charCount > this.maxCount) {
        charCount = this.maxCount;
        this.$refs.content.value = this.$refs.content.value.substring(0, this.maxCount);
      }
      this.$emit("input", { content: this.$refs.content.value, charCount: charCount });
    },
  },
};
</script>
